.Home .lander {
    padding: 80px 0;
    text-align: top;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    color: #999;
    font-weight: 600;
  }
  
  .Home .lander p li {
    font-family: "Open Sans", sans-serif;
    color: #999;
    text-align: left;
  }

  h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #999;
    text-align: left;
  }

  h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: #666;
    text-align: center;
  }

  h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 23px;
    line-height: 1em;
    color: #333;
    text-align: left;
    box-sizing: border-box;
  }

  .labtext {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.7em;
    font-weight: 500;
    color: #666;
    text-align: left; 
    box-sizing: border-box;
  }


  

  