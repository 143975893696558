.App {
  text-align: center;
}

.p-3 {
  background-image: url(./images/background.jpg);
}

.menu {
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
  color: #3d750f !important;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  padding-bottom: 29px;
}

.jumbotron{background-color: #FFFFFF!important;}

#nav-dropdown, #nav-link{
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
  color: #3d750f !important;
}

.socialIcons {
  display: flex;
  justify-content: flex-end;
  color: black;
}

.fa-facebook, .fa-twitter, .fa-instagram, .fa-at, .fa-user-lock {
  vertical-align: top;
  padding-right: 6px;
  padding-bottom: 1px;
}

.fa-facebook:hover { 
  color: #4968ad;
}

.fa-twitter:hover {
  color: #49a1eb;
}

.fa-instagram:hover{
  color: darkmagenta;
}

.fa-at:hover{
  color: darkblue;
} 

.fa-user-lock:hover {
  color: #eb3223;
}

a.socialIcons:hover {
  transform: translateY(-2px);
}
